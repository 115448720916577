import React, { useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  fetchProfilePortfolio,
  portfolios,
  portfoliosIds,
  profile_selected_portfolio,
  selectAllPortFolio,
  selected_portfolio,
  updateAllPortFolio,
  updateSelectedPortFolio,
  update_iscall_portfolio_order,
} from "../../Redux/features/Dashboard/DashBoardSlice";
import down from "../../assets/only-down.svg";
import search from "../../assets/Search.png";
import office from "../../assets/office.png";
import logout from "../../assets/logout.png";
import setting from "../../assets/setting.png";
import { useOnClickOutside } from "../../CustomHooks/useOnClickOutside";
import SettingsPopup from "../SettingsPopup/SettingsPopup";
// import { react_app_login_url } from "../../utils/themeConfig";
import themesConfig from "../../utils/themesConfig";
import { clearTransactionFilter } from "../../Redux/features/Transaction/TransactionListingSlice";
import { clearOrderFilter } from "../../Redux/features/Orders/OrderListingSlice";

function PortfolioDropdown() {
  const dispatch = useAppDispatch();
  const PortFolio = useAppSelector(portfolios);
  const Name = useAppSelector((state: any) => state.dashBoard.profile.name);
  const [Show, setShow] = useState(false);
  const [filterVar, setFilterVar] = useState("");
  // const filterVar = useAppSelector((state) => state.dashBoard.search_portfolio);
  const [view, setView] = useState(false); //for settings popup

  const ref: any = useRef(null);
  useOnClickOutside(ref, () => setShow(false));

  const AddRemovePortFolio = (data: any) => {
    dispatch(updateSelectedPortFolio(data));
  };

  const handleLogout = () => {
    // console.log(app_login_url);
    localStorage.clear();
    setShow(false);
    window.location.href = themesConfig.get_react_app_login_url() + "logout";
  };

  const handleBackOffClick = () => {
    // console.log(themesConfig.get_react_app_login_url(), "handle back office");
  };
  // console.log(
  //   "themesConfig.get_react_app_login_url()",
  //   themesConfig.get_react_app_login_url()
  // );
  return (
    <div ref={ref} className="relative mx-0 ms-options-wrap">
      {/* <button
        onClick={() => setShow((prev) => !prev)}
        className="flex items-center py-2 relative text-menuFontColor  ms-options-wrap-name "
      >
        <span>{Name}</span>
        <div className="item-center flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="6"
            viewBox="0 0 16 9"
            className="ml-1"
          >
            <path
              id="Path_164262"
              data-name="Path 164262"
              d="M1231.588,1137.276c.073-.073.068-.177.112-.263a1.12,1.12,0,0,1,1.73-.363c.06.049.116.1.172.157q2.934,2.9,5.865,5.795c.1.1.152.093.248,0q2.946-2.917,5.9-5.827a1.143,1.143,0,0,1,1.208-.328,1.1,1.1,0,0,1,.55,1.7,2.158,2.158,0,0,1-.187.207q-3.364,3.322-6.728,6.644a1.151,1.151,0,0,1-1.22.336,1.17,1.17,0,0,1-.48-.306q-3.4-3.354-6.8-6.707a1.812,1.812,0,0,1-.371-.608Z"
              transform="translate(-1231.588 -1136.386)"
              fill="var(--arrowColor)"
            />
          </svg>
        </div>
      </button> */}
      <div className={`${Show === true ? "openPortfolio" : ""} p-relative current-design-btn ml-10`}>
        <button id="second-element" className="custome-select new-btn-2" type="button" onClick={() => setShow((prev) => !prev)}>
          <span className="new-btn-2-span">
            <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path
                className="innerColor"
                id="portfolio_blue"
                d="M64 64C46.3 64 32 78.3 32 96l0 320c0 17.7 14.3 32 32 32l384 0c17.7 0 32-14.3 32-32l0-256c0-17.7-14.3-32-32-32l-156.1 0c-17 0-33.3-6.7-45.3-18.7L210.7 73.4c-6-6-14.1-9.4-22.6-9.4L64 64zM0 96C0 60.7 28.7 32 64 32l124.1 0c17 0 33.3 6.7 45.3 18.7l35.9 35.9c6 6 14.1 9.4 22.6 9.4L448 96c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96z"
              />
            </svg>
          </span>
          <div className="tooltiptext tool-right">
            <p>Portfolios</p>
          </div>
        </button>
      </div>
      {Show && (
        <div
          className={`${
            PortFolio.length > 1 ? "" : ""
          } z-[999]  min-w-[315px] font-Inter right-1 absolute bg-white text-primaryText rounded-10px px-4 py-4 ms-options`}
        >
          <div className="flex flex-col items-center">
            <div className="flex justify-start h-[2.5rem] w-full rounded-10px bg-[#EBEDEE] ms-search">
              <img className="px-3 py-[9px] rounded-10px bg-[#EBEDEE]" src={search} alt="" />
              <input
                className="outline-none rounded-10px  bg-[#ebedee] placeholder:font-Inter "
                type="text "
                value={filterVar}
                onChange={(e) => setFilterVar(e.target.value)}
                placeholder="Search Portfolio"
                // onChange={(e) => dispatch(search_portfolio(e.target.value))}
              />
            </div>
            <div className="flex w-full flex-start px-1" key={"EBEDEE"}>
              {
                <button
                  onClick={
                    PortFolio.filter((i: any) => i.status === true).length !== PortFolio.length
                      ? () => dispatch(selectAllPortFolio())
                      : () => dispatch(updateAllPortFolio())
                  }
                  className="capitalize hover:underline py-2 text-sm rounded-lg "
                  key={"EBEDEEEBEDEE"}
                >
                  {PortFolio.filter((i: any) => i.status === true).length !== PortFolio.length ? "Select all" : "Unselect all"}
                </button>
              }
            </div>
            <div className="flex items-start justify-start minHeightCustom text-sm overflow-auto flex-col w-full" id="portfolio-selection">
              {PortFolio &&
                PortFolio.filter((temp) => temp?.name?.toLowerCase().includes(filterVar?.toLowerCase())).map((i: any) => (
                  <>
                    <label key={i.id} className="p-1 py-1.5 w-full hover:cursor-pointer text-porfolioTextColor flex justify-start" htmlFor={i.id}>
                      <input
                        id={i.id}
                        className="mr-2 mt-[2px 5px 0 0] border-checkBoxColor rounded-sm form-checkbox checked:bg-checkBoxColor checked:focus:bg-checkBoxColor ring-offset-0 focus:ring-offset-0 checked:hover:bg-checkBoxColor focus:ring-0  styckbox"
                        type="checkbox"
                        checked={i.status}
                        onChange={(e) => AddRemovePortFolio(e.target.value)}
                        defaultValue={i.id}
                      />
                      {i.name}
                    </label>
                  </>
                ))}
            </div>

            <div className=" flex w-full flex-row justify-end py-2 pb-0">
              <button
                disabled={PortFolio.filter((i: any) => i.status === true).length === 0}
                onClick={() => {
                  dispatch(
                    fetchProfilePortfolio({
                      portfolio_ids: PortFolio.filter((i: any) => i.status === true).map((i: any) => String(i.id)),
                    })
                  );
                  setFilterVar("");
                  setShow(false);

                  dispatch(clearOrderFilter());
                  // dispatch(update_iscall_portfolio_order(true));
                }}
                className={
                  PortFolio.filter((i: any) => i.status === true).length === 0
                    ? "bg-secondaryBg text-white text-sm py-2 px-10 rounded-[10px] hover:text-primaryText hover:bg-layoutBg hover:border-primaryText border cursor-not-allowed"
                    : "bg-secondaryBg text-white text-sm py-2 px-10 rounded-[10px] hover:text-primaryText hover:bg-layoutBg hover:border-primaryText border"
                }
              >
                <span>OK</span>
              </button>
            </div>

            {/*<div className="flex items-start w-full flex-col text-sm text-porfolioTextColor">*/}
            {/* <a
                href={`${themesConfig.get_react_app_login_url()}`}
                className="flex items-center p-2 hover:bg-[#f2f2f2] w-full"
                target="_blank"
                // onClick={() => handleBackOffClick()}
              >
                <img className="mr-2" src={office} alt="" /> Back Office
              </a> */}
            {/*<button
                className="flex items-center p-2 hover:bg-[#f2f2f2] w-full"
                onClick={() => {
                  setShow(false);
                  setView(true);
                }}
              >
                <img className="mr-2" src={setting} alt="" /> Settings
              </button>
              <button
                className="flex items-center p-2 hover:bg-[#f2f2f2] w-full  user-details-logout"
                onClick={() => handleLogout()}
              >
                <img className="mr-2" src={logout} alt="" /> Logout
              </button>
            </div>*/}
          </div>
        </div>
      )}
      <SettingsPopup view={view} setView={setView} />
    </div>
  );
}

export default PortfolioDropdown;
