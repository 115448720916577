import { useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import DropdownArrow from "./DropdownArrow";

interface MenuItem {
  id: string;
  name: string;
  url: string;
  url_type: string;
  open_in_new_tab: number;
  icon?: string;
  view_permission_id?: string;
  content: string;
  children?: MenuItem[];
  _token?: string;
}

interface DropdownItemProps {
  item: MenuItem;
  depth?: number;
}

const DropdownItem: React.FC<DropdownItemProps> = ({ item, depth = 0 }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleMouseEnter = () => {
    if (item.children && item.children.length > 0) {
      setIsOpen(true);
    }
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const linkClasses = classNames("", {
    "bg-[#071932] text-white hover:bg-[#071932] hover:text-whiteRgb":
      (depth === 0 && item.name === "Bookings") || (depth === 0 && item.name === "Clients"),
    "text-[#968A8A]": depth === 0 && item.name !== "Trade Entry" && item.name !== "Clients",
    "hover:bg-[#dce6f3] border border-transparent hover:border-[#D2E0F2] hover:border-b-[#637A9A] hover:text-blackRgb":
      depth === 0 && item.name !== "Trade Entry" && item.name !== "Clients",
    " px-4 py-3 h-[50px] cursor-pointer rounded-t-[20px] text-[14px] flex items-center justify-between": depth === 0,
    " px-2 min-w-[190px] hover:bg-[#dce6f3] py-2 flex items-center z-50 justify-between text-[14px] font-light": depth !== 0,
  });

  return (
    <div className="relative" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <a target={item.open_in_new_tab === 1 ? "_blank" : "_self"} href={item.url} className={linkClasses}>
        <span
          className={classNames("", {
            "min-w-[190px] max-w-[600px] w-full flex": depth !== 0,
          })}
        >
          {item.name}
        </span>
        {item.children && depth !== 0 && (
          <div className="text-gray ">
            <DropdownArrow />
          </div>
        )}
      </a>

      {isOpen && item.children && (
        <div
          className={classNames(
            "absolute min-w-[190px] w-max flex flex-col shadow-[0px_4px_4px_0px_#00000040] border border-[#e5e5e5] z-[999] bg-white",
            {
              "left-0 top-[3.1rem]": depth === 0,
              "left-full -top-0.5": depth !== 0,
            }
          )}
        >
          {item.children.map((child) => (
            <DropdownItem key={child.id} item={child} depth={depth + 1} />
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownItem;
