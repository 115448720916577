var theme = "emfi";
var react_app_api_endpoint = "https://admin-uat.emfi.uk/api/workstation/";
var react_app_login_url = "https://admin-uat.emfi.uk/";

export default {
  getThemeName: function () {
    return theme;
  },
  getTheme: function () {
    return this.getThemeoption();
  },
  get_react_app_api_endpoint: function () {
    return this.setDomainBaseTheme("get_react_app_api_endpoint");
  },
  get_react_app_login_url: function () {
    return this.setDomainBaseTheme("get_react_app_login_url");
  },
  getThemeTitle: function () {
    return theme.toUpperCase() + " | Order Entry";
  },
  setTheme: function (val) {
    theme = val;
  },
  getThemeoption: function () {
    let options = {
      emfi: {
        theme_logo: "logo.svg",
        theme_white_logo: "logo.svg",
        theme_class: "theme-emfi",
        theme_favicon: "https://www.emfi.uk/favicon.ico",
        chart_primary_color: "#001a34",
        chart_secondary_color: "",
        chart_for_color: "#333333",
        chart_grid_border_color: "#dbdbdb",
        is_own_menu: true,
      },
      arxfe: {
        theme_logo: "arxfe-logo.svg",
        theme_white_logo: "arxfe-white-logo.png",
        theme_class: "theme-arxfe",
        theme_favicon: "favicon.png",
        chart_primary_color: "#066d3f",
        chart_secondary_color: "",
        chart_for_color: "#066d3f",
        chart_grid_border_color: "#333333",
        is_own_menu: true,
      },
      demoarxfe: {
        theme_logo: "arxfe-logo.svg",
        theme_white_logo: "arxfe-white-logo.png",
        theme_class: "theme-arxfe",
        theme_favicon: "favicon.png",
        chart_primary_color: "#066d3f",
        chart_secondary_color: "",
        chart_for_color: "#066d3f",
        chart_grid_border_color: "#333333",
        is_own_menu: true,
      },
      britannia: {
        theme_logo: "logo.png",
        theme_white_logo: "logo-white.png",
        theme_class: "theme-britannia",
        theme_favicon: "favicon.png",
        chart_primary_color: "#000000",
        chart_secondary_color: "",
        chart_for_color: "#000000",
        chart_grid_border_color: "#212529",
        is_own_menu: true,
      },
      hblbank: {
        theme_logo: "logo.svg",
        theme_white_logo: "white-logo.png",
        theme_class: "theme-hblbankuk",
        theme_favicon: "favicon.png",
        chart_primary_color: "#008269",
        chart_secondary_color: "",
        chart_for_color: "#008269",
        chart_grid_border_color: "#333333",
        is_own_menu: true,
      },
      gpp: {
        theme_logo: "logo.svg",
        theme_white_logo: "white-logo.svg",
        theme_class: "theme-gpp",
        theme_favicon: "favicon.png",
        chart_primary_color: "#006db2",
        chart_secondary_color: "",
        chart_for_color: "#006db2",
        chart_grid_border_color: "#6d6e71",
        is_own_menu: true,
      },
      nobilis: {
        theme_logo: "logo.png",
        theme_white_logo: "white-logo.svg",
        theme_class: "theme-nobilis",
        theme_favicon: "favicon.ico",
        chart_primary_color: "#006db2",
        chart_secondary_color: "",
        chart_for_color: "#006db2",
        chart_grid_border_color: "#6d6e71",
        is_own_menu: true,
      },
      arwoly: {
        theme_logo: "logo.png",
        theme_white_logo: "white-logo.svg",
        theme_class: "theme-arwoly",
        theme_favicon: "favicon.ico",
        chart_primary_color: "#006db2",
        chart_secondary_color: "",
        chart_for_color: "#006db2",
        chart_grid_border_color: "#6d6e71",
        is_own_menu: false,
      },
      latamconsultus: {
        theme_logo: "logo.png",
        theme_white_logo: "white-logo.svg",
        theme_class: "theme-latamconsultus",
        theme_favicon: "favicon.ico",
        chart_primary_color: "#006db2",
        chart_secondary_color: "",
        chart_for_color: "#006db2",
        chart_grid_border_color: "#6d6e71",
        is_own_menu: true,
      },
      fintech: {
        theme_logo: "logo.svg",
        theme_white_logo: "logo.svg",
        theme_class: "theme-fintech",
        theme_favicon: "favicon.ico",
        chart_primary_color: "#0d86ff",
        chart_secondary_color: "",
        chart_for_color: "#12161e",
        chart_grid_border_color: "#636872",
        is_own_menu: false,
      },
    };
    // console.log("options[theme]", options[theme], theme);
    return options[theme];
  },
  //  set theme based on domain
  setDomainBaseTheme: function (returnURL = "") {
    const hoststr = window.location.host;
    // const hoststr = "http://arxfe-workstation.emfi.uk";
    // const hoststr = "http://britannia-workstation.emfi.uk";
    // const hoststr = 'https://demoworkstation.emfi.uk';
    // const hoststr = 'https://workstation.emfi.uk';
    // const hoststr = 'https://workstation.mink.emfi.uk';
    // const hoststr = 'https://workstation.developmentlabs.co';
    //check is local or not
    const islive = hoststr.includes("workstation.emfi.uk");
    const isUatlive = hoststr.includes("workstation-uat.emfi.uk");
    const isSlablive = hoststr.includes("workstation.slabss.com");
    const isMinklive = hoststr.includes("workstation.mink.emfi.uk");
    const isFintech = hoststr.includes("workstation.developmentlabs.co");
    let themename = "emfi";
    let host = "";
    let parts = "";
    if (islive || isUatlive || isSlablive || isMinklive || isFintech) {
      //check http include ot not
      const ishttpinclude = hoststr.includes("http");
      if (ishttpinclude) {
        const strhttp = "http://";
        const strhttps = "https://";
        const ishttp = hoststr.includes(strhttp);
        const ishttps = hoststr.includes(strhttps);
        if (ishttps) {
          host = hoststr.split(strhttps);
        }
        if (ishttp) {
          host = hoststr.split(strhttp);
        }
        if (isMinklive) {
          parts = host[1].split(".");
        } else {
          parts = host[1].split("-");
        }
        // parts = host[1].split('-');
      } else {
        host = hoststr;
        parts = host.split("-");
      }

      themename = parts[0];
      if (isMinklive) {
        // themename = parts[1];
        themename = "mink";
      }
      if (
        parts[0] === "workstation.slabss.com" ||
        parts[0] === "workstation.emfi.uk" ||
        parts[0] === "demoworkstation.emfi.uk" ||
        parts[0] === "workstation.developmentlabs.co" ||
        (parts[0] === "workstation" && !isMinklive)
      ) {
        if (isFintech) {
          themename = "fintech";
        } else {
          themename = "emfi";
        }

        //live
        if (parts[0] === "workstation.slabss.com") {
          react_app_api_endpoint = process.env.REACT_APP_API_ENDPOINT.replace("[[BASEURL]]", "login.slabss.com");
          react_app_login_url = process.env.REACT_APP_LOGIN_URL.replace("[[BASEURL]]", "login.slabss.com");
        }
        //live
        if (parts[0] === "workstation.emfi.uk") {
          react_app_api_endpoint = process.env.REACT_APP_API_ENDPOINT.replace("[[BASEURL]]", "login.emfi.uk");
          react_app_login_url = process.env.REACT_APP_LOGIN_URL.replace("[[BASEURL]]", "login.emfi.uk");
        }
        //UAT live
        if (parts[0] === "workstation") {
          react_app_api_endpoint = process.env.REACT_APP_API_ENDPOINT.replace("[[BASEURL]]", "admin-uat.emfi.uk");
          react_app_login_url = process.env.REACT_APP_LOGIN_URL.replace("[[BASEURL]]", "admin-uat.emfi.uk");
        }
        //demo
        if (parts[0] === "demoworkstation.emfi.uk") {
          react_app_api_endpoint = process.env.REACT_APP_API_ENDPOINT.replace("[[BASEURL]]", "demo.emfi.uk");
          react_app_login_url = process.env.REACT_APP_LOGIN_URL.replace("[[BASEURL]]", "demo.emfi.uk");
        }
        //fintech
        if (parts[0] === "workstation.developmentlabs.co") {
          react_app_api_endpoint = process.env.REACT_APP_API_ENDPOINT.replace("[[BASEURL]]", "fintech.developmentlabs.co");
          react_app_login_url = process.env.REACT_APP_LOGIN_URL.replace("[[BASEURL]]", "fintech.developmentlabs.co");
        }
      } else {
        //all branding
        let setURL = themename + ".emfi.uk";
        react_app_api_endpoint = process.env.REACT_APP_API_ENDPOINT.replace("[[BASEURL]]", setURL);
        react_app_login_url = process.env.REACT_APP_LOGIN_URL.replace("[[BASEURL]]", setURL);
      }
    } else {
      react_app_api_endpoint = process.env.REACT_APP_API_ENDPOINT.replace("[[BASEURL]]", "login.emfi.local");
      react_app_login_url = process.env.REACT_APP_LOGIN_URL.replace("[[BASEURL]]", "login.emfi.local");
    }

    // console.log(
    //   "in themeconfig react_app_api_endpoint",
    //   react_app_api_endpoint
    // );
    // console.log("in themeconfig react_app_login_url", react_app_login_url);
    // console.log("domain parts", themename);
    // themename = 'emfi';
    // return;
    this.setTheme(themename);
    if (returnURL === "get_react_app_login_url") {
      return react_app_login_url;
    }
    if (returnURL === "get_react_app_api_endpoint") {
      return react_app_api_endpoint;
    }
    return "";
  },
  getBrandMenu: function () {
    const curretn_theme = this.getTheme();
    let defaultmenuItem = "emfi";
    if (curretn_theme.is_own_menu) {
      defaultmenuItem = theme;
    }
    let menuItems = {
      emfi: {
        menu: [
          {
            key: "home",
            title: "Home",
            en_link: process.env.REACT_APP_FRONT_URL + "english",
            es_link: "/espanol",
          },
          // {
          //   key: "pricing",
          //   title: "Pricing",
          //   en_link: process.env.REACT_APP_FRONT_URL + "english/pricing",
          //   es_link: "/espanol/pricing",
          // },
          {
            key: "intelligence",
            title: "Intelligence",
            en_link: process.env.REACT_APP_FRONT_URL + "english/intelligence",
            es_link: "/espanol/intelligence",
          },
          {
            key: "services",
            title: "Services",
            en_link: process.env.REACT_APP_FRONT_URL + "english/services",
            es_link: "/espanol/services",
          },
          {
            key: "about",
            title: "About",
            en_link: process.env.REACT_APP_FRONT_URL + "english/about",
            es_link: "/espanol/about",
          },
          {
            key: "contact",
            title: "Contact",
            en_link: process.env.REACT_APP_FRONT_URL + "english/contact",
            es_link: "/espanol/contact",
          },
        ],
      },
      britannia: {
        menu: [
          {
            key: "home",
            title: "Home",
            en_link: "https://britannia.com/",
            es_link: "https://britannia.com/",
          },
          {
            key: "about-us",
            title: "About us",
            en_link: "https://britannia.com/en/bfgl-overview",
            es_link: "https://britannia.com/en/bfgl-overview",
          },
          {
            key: "bank-trust",
            title: "Bank & Trust",
            en_link: "https://britannia.com/bt",
            es_link: "https://britannia.com/bt",
          },
          {
            key: "securities",
            title: "Securities",
            en_link: "https://britannia.com/sl",
            es_link: "https://britannia.com/sl",
          },
          {
            key: "global-payments",
            title: "Global Payments",
            en_link: "https://britannia.com/gp",
            es_link: "https://britannia.com/gp",
          },
        ],
      },
      arxfe: {
        menu: [
          {
            key: "home",
            title: "Home",
            en_link: "https://arxfe.com/",
            es_link: "https://arxfe.com/",
          },
          {
            key: "solution",
            title: "Solution",
            en_link: "https://arxfe.com/solution/",
            es_link: "https://arxfe.com/solution/",
          },
          {
            key: "news",
            title: "News",
            en_link: "https://arxfe.com/news/default",
            es_link: "https://arxfe.com/news/default",
          },
          {
            key: "about-us",
            title: "About Us",
            en_link: "https://arxfe.com/about_us",
            es_link: "https://arxfe.com/about_us",
          },
          {
            key: "contact",
            title: "Contact",
            en_link: "https://arxfe.com/contact",
            es_link: "https://arxfe.com/contact",
          },
        ],
      },
      demoarxfe: {
        menu: [
          {
            key: "home",
            title: "Home",
            en_link: "https://arxfe.com/",
            es_link: "https://arxfe.com/",
          },
          {
            key: "solution",
            title: "Solution",
            en_link: "https://arxfe.com/solution/",
            es_link: "https://arxfe.com/solution/",
          },
          {
            key: "news",
            title: "News",
            en_link: "https://arxfe.com/news/default",
            es_link: "https://arxfe.com/news/default",
          },
          {
            key: "about-us",
            title: "About Us",
            en_link: "https://arxfe.com/about_us",
            es_link: "https://arxfe.com/about_us",
          },
          {
            key: "contact",
            title: "Contact",
            en_link: "https://arxfe.com/contact",
            es_link: "https://arxfe.com/contact",
          },
        ],
      },
      hblbank: {
        menu: [
          {
            key: "home",
            title: "Home",
            en_link: "https://hblbankuk.com/",
            es_link: "https://hblbankuk.com/",
          },
          {
            key: "about",
            title: "About",
            en_link: "https://hblbankuk.com/about/",
            es_link: "https://hblbankuk.com/about/",
          },
          {
            key: "personal",
            title: "Personal",
            en_link: "https://hblbankuk.com/personal/",
            es_link: "https://hblbankuk.com/personal/",
          },
          {
            key: "business",
            title: "Business",
            en_link: "https://hblbankuk.com/business/",
            es_link: "https://hblbankuk.com/business/",
          },
          {
            key: "trade",
            title: "Trade",
            en_link: "https://hblbankuk.com/trade/",
            es_link: "https://hblbankuk.com/trade/",
          },
          {
            key: "fi",
            title: "FI",
            en_link: "https://hblbankuk.com/fi/",
            es_link: "https://hblbankuk.com/fi/",
          },
          {
            key: "rates",
            title: "Rates",
            en_link: "https://hblbankuk.com/rates/",
            es_link: "https://hblbankuk.com/rates/",
          },
          {
            key: "contact-us",
            title: "Contact Us",
            en_link: "https://hblbankuk.com/contact-us/",
            es_link: "https://hblbankuk.com/contact-us/",
          },
        ],
      },
      gpp: {
        menu: [
          {
            key: "home",
            title: "Home",
            en_link: "https://www.gpp.group/",
            es_link: "https://www.gpp.group/",
          },
          {
            key: "news",
            title: "News",
            en_link: "https://www.gpp.group/news",
            es_link: "https://www.gpp.group/news",
          },
          {
            key: "careers",
            title: "Careers",
            en_link: "https://www.gpp.group/careers",
            es_link: "https://www.gpp.group/careers",
          },
          {
            key: "about",
            title: "About",
            en_link: "https://www.gpp.group/about",
            es_link: "https://www.gpp.group/about",
          },
          {
            key: "people",
            title: "People",
            en_link: "https://www.gpp.group/people",
            es_link: "https://www.gpp.group/people",
          },
          {
            key: "contact",
            title: "Contact",
            en_link: "https://www.gpp.group/contact",
            es_link: "https://www.gpp.group/contact",
          },
          {
            key: "group",
            title: "Group",
            en_link: "https://gpp.group/group",
            es_link: "https://gpp.group/group",
          },
        ],
      },
      nobilis: {
        menu: [
          {
            key: "Us",
            title: "Us",
            en_link: "https://www.nobilis.uy/quienes-somos/ct_15/en/",
            es_link: "https://www.nobilis.uy/our-company/ct_15/es/",
          },
          {
            key: "Services",
            title: "Services",
            en_link: "https://www.nobilis.uy/que-nos-diferencia/ct_7/en/",
            es_link: "https://www.nobilis.uy/what-differentiates-us/ct_7/es/",
          },
          {
            key: "Financial Education",
            title: "Financial Education",
            en_link: "https://www.nobilis.uy/nobilis-tips/se_9/es/",
            es_link: "https://www.nobilis.uy/nobilis-tips/se_9/es/",
          },
          {
            key: "Legal",
            title: "Legal",
            en_link: "https://www.nobilis.uy/legal/ct_14/en/",
            es_link: "https://www.nobilis.uy/legal/ct_14/es/",
          },
          {
            key: "Press",
            title: "Press",
            en_link: "https://www.nobilis.uy/prensa/ct_31/es/",
            es_link: "https://www.nobilis.uy/prensa/ct_31/es/",
          },
        ],
      },
      latamconsultus: {
        menu: [
          {
            key: "About Us",
            title: "About Us",
            en_link: "https://www.latamconsultus.com/en/home/#our-company",
            es_link: "https://www.gpp.group/",
          },
          {
            key: "What We Do",
            title: "What We Do",
            en_link: "https://www.latamconsultus.com/en/home/#what-we-do",
            es_link: "https://www.latamconsultus.com/en/home/#what-we-do",
          },
          {
            key: "Team",
            title: "Team",
            en_link: "https://www.latamconsultus.com/en/home/#our-team",
            es_link: "https://www.latamconsultus.com/en/home/#our-team",
          },
          {
            key: "Experiences",
            title: "Experiences",
            en_link: "https://www.gpp.group/about/#experiences",
            es_link: "https://www.gpp.group/about/#experiences",
          },
          {
            key: "Contact Us",
            title: "Contact Us",
            en_link: "https://www.latamconsultus.com/en/home/#contactus",
            es_link: "https://www.latamconsultus.com/en/home/#contactus",
          },
        ],
      },
    };
    return menuItems[defaultmenuItem];
  },
};
