import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { fetchData, postData, socketIO } from "../../../services/apiFunctions";
import moment from "moment";
import { dashBoardtypes } from "../../../types/components/types";
import { RootState } from "../../app/store";
import { toast } from "react-toastify";

// let socketURL = process.env.REACT_APP_SOCKET_URL;
// const socket = socketIO(socketURL);

export const fetchProfile: any = createAsyncThunk("dashBoard/fetchProfile", async () => {
  const response: any = await fetchData("profile");
  return response.data;
});

export const fetchTableHiddenFields: any = createAsyncThunk("dashBoard/fetchTableHiddenFields", async () => {
  const response: any = await fetchData("gettableHiddenfields");
  return response.data;
});

export const fetchKill: any = createAsyncThunk("dashBoard/trad-kill", async (data) => {
  const response: any = await postData("trad-kill", data);
  return response.data;
});

export const fetchProfileCurrency: any = createAsyncThunk("dashBoard/fetchProfileCurrency", async (data) => {
  const response: any = await postData("profile/currency", data);
  return response.data;
});
export const fetchProfileCounterparties: any = createAsyncThunk("dashBoard/fetchProfileCounterparties", async (data) => {
  const response: any = await postData("profile/counterparties", data);
  return response.data;
});
// export const fetchProfiledefaultsecurity: any = createAsyncThunk("dashBoard/fetchProfiledefaultsecurity", async (data) => {
//   const response: any = await postData("profile/defaultsecurity", data);
//   return response.data;
// });
export const fetchProfilePortfolio: any = createAsyncThunk("dashBoard/fetchProfilePortfolio", async (data) => {
  const response: any = await postData("profile/portfolio", data);
  return response.data;
});
export const fetchkillcount: any = createAsyncThunk("dashBoard/getkillcount", async (data) => {
  const response: any = await fetchData("get-kill-count");
  return response.data;
});
export const postBulkTradeUpload: any = createAsyncThunk("dashBoard/bulkTradeUpload", async (data) => {
  const response: any = await postData("bulk-trade-upload", data);
  return response.data;
});
export const postUserMenu: any = createAsyncThunk("dashBoard/getUserMenu", async (data) => {
  const response: any = await postData("get-user-menu", data);
  return response.data;
});
export const fetchDefaultLoadData: any = createAsyncThunk("dashBoard/getDefaultLoadData", async (data) => {
  const response: any = await fetchData("getUserDefaultData");
  return response.data;
});
const initialState: dashBoardtypes = {
  show_handbook: false,
  is_tour_guide: false,
  show_calander: false,
  bulkUploadStatus: "success",
  menuDataStatus: "loading",
  menuData: [],
  profileStatus: "loading",
  profileCurrencyStatus: "loading",
  profilePortFolioStatus: "loading",
  profileCounterpartiesStatus: "loading",
  is_rfq_access: "",
  is_bulk_upload: "",
  full_security_name: "",
  is_allow_execution_and_settlement: false,
  portfolios: [],
  trading_portfolios: [],
  fx_currency_data: {
    currencyList: [],
    selected_currency: "BASE",
  },
  profile: {
    department: 0,
    id: "",
    selected_currency: "",
    selected_portfolio: "",
    is_report_country: 0,
    report_send_time: 0,
    country_ids: "",
    regions_ids: "",
  },
  search_portfolio: "",
  portfoliosIds: [],
  hiddenFields: [],
  counterparties: {},
  selected_portfolio: [],
  start_date: moment().subtract(1, "year").format("YYYY-MM-DD"),
  end_date: moment().format("YYYY-MM-DD"),
  is_load_security: false,
  is_load_security_message: "Fetching Data..",
  default_security_set: {
    security_id: Number(localStorage.getItem("login-id")) === 2 || Number(localStorage.getItem("login-id")) === 10 ? 11 : 19251,
    conid: Number(localStorage.getItem("login-id")) === 2 || Number(localStorage.getItem("login-id")) === 10 ? 12087792 : "",
    iex_symbol: Number(localStorage.getItem("login-id")) === 2 || Number(localStorage.getItem("login-id")) === 10 ? "EURUSD" : "",
    market_id: Number(localStorage.getItem("login-id")) === 2 || Number(localStorage.getItem("login-id")) === 10 ? 6 : 8,
    is_update: false,
    tabName:
      Number(localStorage.getItem("login-id")) === 2 || Number(localStorage.getItem("login-id")) === 10
        ? "EURUSD"
        : "MS INVF EMERGING LEADERS EQUITY C",
    isin_code: Number(localStorage.getItem("login-id")) === 2 || Number(localStorage.getItem("login-id")) === 10 ? "EUR Curncy" : "LU0819839092",
    currency: Number(localStorage.getItem("login-id")) === 2 || Number(localStorage.getItem("login-id")) === 10 ? "EUR" : "USD",
    ib_exchange: Number(localStorage.getItem("login-id")) === 2 || Number(localStorage.getItem("login-id")) === 10 ? "IDEALPRO" : "",
  },
  kill_counter: null,
  fetch_counter_status: "loading",
  is_socket_connected: 2, // 2= connected and 1= disconnected
  socket_connect_obj: [],
  security_subscription: {
    security_id: 11,
    conid: 12087792,
    iex_symbol: "EURUSD",
    market_id: 6,
    is_update: false,
    tabName: "EURUSD",
    isin_code: "EUR Curncy",
  },
  security_unsubscription: {},
  emit_lineTimeline: {},
  socket_remove_all_listeners: "",
  IB_price_data: [],
  rfs_stream_channel_data: [],
  rfq_quote_data: {
    sec: "",
    rid: "",
    type: "",
    qid: "",
    vid: "",
    px: "",
    sz: "",
    tm: "",
  },
  iscall_portfolio_order: false,
  trade_notification_data: [],
  // default_security_set: {
  //   security_id: 225196,
  //   conid: 247954087,
  //   iex_symbol: "",
  //   market_id: 5,
  //   is_update: false,
  // },
};

const DashBoard = createSlice({
  name: "dashBoard",
  initialState,
  reducers: {
    addHiddenFields: (state: dashBoardtypes, action: PayloadAction<Array<any>>) => {
      if (!state.hiddenFields.includes(action.payload)) {
        // If it doesn't exist, add the key to the array
        return {
          ...state,
          hiddenFields: [...state.hiddenFields, action.payload],
        };
      }
      // If it already exists, return the current state
      return state;
    },
    removeHiddenFields: (state: dashBoardtypes, action: PayloadAction<Array<any>>) => {
      if (state.hiddenFields.includes(action.payload)) {
        // If it doesn't exist, add the key to the array
        return {
          ...state,
          hiddenFields: state.hiddenFields.filter((item) => item !== action.payload),
        };
      }
      // If it already exists, return the current state
      return state;
    },
    updateDateRange: (state, action) => {
      return {
        ...state,
        start_date: action.payload.start_date,
        end_date: action.payload.end_date,
      };
    },
    updateDefaultSecuritySet: (state: dashBoardtypes, { payload }: PayloadAction<any>) => {
      // let isin = payload.text.split("|")[1].trim() ?? "";
      let isin_code = payload.text.split("|");
      let isin = "";
      if (isin_code.length > 1) {
        isin = isin_code[1].trim();
      } else {
        isin = isin_code[0].trim();
      }
      return {
        ...state,
        default_security_set: {
          // ...state.default_security_set,
          security_id: payload.id,
          conid: payload.ib_conid,
          iex_symbol: payload.iex_symbol,
          market_id: payload.market_id,
          is_update: true,
          tabName: payload.tabName,
          isin_code: payload.isin_code !== "" ? payload.isin_code : isin,
          ib_exchange: payload.ib_exchange,
          currency: payload.currency,
        },
        full_security_name: payload.text,
      };
    },
    updateSelectedPortFolio: (state: dashBoardtypes, action: PayloadAction<Array<any>>) => {
      return {
        ...state,
        portfolios: state.portfolios.map((content: any) => (content.id == action.payload ? { ...content, status: !content.status } : content)),
      };
    },
    updateAllPortFolio: (state: dashBoardtypes) => {
      return {
        ...state,
        portfolios: state.portfolios.map((content: any) => {
          return { ...content, status: false };
        }),
      };
    },
    selectAllPortFolio: (state: dashBoardtypes) => {
      return {
        ...state,
        portfolios: state.portfolios.map((content: any) => {
          return { ...content, status: true };
        }),
      };
    },
    updateStartDate: (state: dashBoardtypes, action: PayloadAction<string>) => {
      return {
        ...state,
        start_date: action.payload,
      };
    },
    updateEndDate: (state: dashBoardtypes, action: PayloadAction<string>) => {
      return {
        ...state,
        end_date: action.payload,
      };
    },
    update_is_report_country: (state: dashBoardtypes, action: PayloadAction<number>) => {
      return {
        ...state,
        profile: {
          ...state.profile,
          is_report_country: action.payload,
        },
      };
    },
    update_is_socket_connected: (state: dashBoardtypes, action: PayloadAction<number>) => {
      return {
        ...state,
        is_socket_connected: action.payload,
      };
    },
    update_socket_connect_obj: (state: dashBoardtypes, action: PayloadAction<any>) => {
      return {
        ...state,
        socket_connect_obj: action.payload,
      };
    },
    update_security_subscription: (state: dashBoardtypes, action: PayloadAction<any>) => {
      return {
        ...state,
        security_subscription: action.payload,
      };
    },
    update_security_unsubscription: (state: dashBoardtypes, action: PayloadAction<any>) => {
      return {
        ...state,
        security_unsubscription: action.payload,
      };
    },
    update_emit_lineTimeline: (state: dashBoardtypes, action: PayloadAction<any>) => {
      return {
        ...state,
        emit_lineTimeline: action.payload,
      };
    },
    update_socket_remove_all_listeners: (state: dashBoardtypes, action: PayloadAction<any>) => {
      return {
        ...state,
        socket_remove_all_listeners: action.payload,
      };
    },
    update_IB_price_data: (state: dashBoardtypes, action: PayloadAction<any>) => {
      return {
        ...state,
        IB_price_data: action.payload,
      };
    },
    update_rfs_stream_channel_data: (state: dashBoardtypes, action: PayloadAction<any>) => {
      return {
        ...state,
        rfs_stream_channel_data: action.payload,
      };
    },

    update_rfq_quote_data: (state: dashBoardtypes, action: PayloadAction<any>) => {
      return {
        ...state,
        rfq_quote_data: action.payload,
      };
    },
    update_trade_notification_data: (state: dashBoardtypes, action: PayloadAction<any>) => {
      return {
        ...state,
        trade_notification_data: action.payload,
      };
    },

    update_report_send_time: (state: dashBoardtypes, action: PayloadAction<number>) => {
      return {
        ...state,
        profile: {
          ...state.profile,
          report_send_time: action.payload,
        },
      };
    },
    update_country_ids: (state: dashBoardtypes, action: PayloadAction<string>) => {
      return {
        ...state,
        profile: {
          ...state.profile,
          country_ids: action.payload,
        },
      };
    },
    update_regions_ids: (state: dashBoardtypes, action: PayloadAction<string>) => {
      return {
        ...state,
        profile: {
          ...state.profile,
          regions_ids: action.payload,
        },
      };
    },
    update_current_currency: (state: dashBoardtypes, action: PayloadAction<any>) => {
      return {
        ...state,
        profile: {
          ...(state.profile.selected_currency = action.payload),
        },
      };
    },

    search_portfolio: (state: dashBoardtypes, action: PayloadAction<string>) => {
      return {
        ...state,
        search_portfolio: action.payload,
      };
    },
    update_iscall_portfolio_order: (state: dashBoardtypes, action: PayloadAction<boolean>) => {
      return {
        ...state,
        iscall_portfolio_order: action.payload,
      };
    },
    update_kill_counter: (state: dashBoardtypes, action: PayloadAction<any>) => {
      return {
        ...state,
        kill_counter: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfile.pending, (state) => {
        return {
          ...state,
          profileStatus: "loading",
        };
      })
      .addCase(fetchProfile.fulfilled, (state, { payload }) => {
        let user_id = payload.data.profile.id;
        let user_name = payload.data.profile.name;
        // socket.emit("user_join", { user_id, user_name });
        localStorage.setItem("login-id", user_id);
        localStorage.setItem("login-name", user_name);
        localStorage.setItem("stream-channel", "ib_price_stream_" + payload.data.profile.id);
        localStorage.setItem("stream-holding-channel", "ib_holding_price_stream_" + payload.data.profile.id);
        return {
          ...state,
          is_rfq_access: payload.data.is_rfq_access,
          is_tour_guide: payload.data.is_tour_guide,
          is_bulk_upload: payload.data.is_bulk_upload,
          is_allow_execution_and_settlement: payload.data.is_allow_execution_and_settlement,
          portfolios: payload.data.portfolios,
          trading_portfolios: payload.data.trading_portfolios,
          fx_currency_data: payload.data.fx_currency_data,
          profile: {
            ...payload.data.profile,
            selected_portfolio: payload.data.profile.selected_portfolio,
          },
          portfoliosIds: payload.data.portfoliosIds,
          // counterparties: payload.data.counterparties,
          selected_portfolio: payload.data.selected_portfolio,
          profileStatus: "success",
          show_calander: payload.data.show_calander,
          show_handbook: payload.data.show_handbook,
        };
      })
      .addCase(fetchProfile.rejected, (state) => {
        return {
          ...state,
          profileStatus: "error",
        };
      })
      .addCase(fetchTableHiddenFields.pending, (state) => {
        return {
          ...state,
          profileStatus: "loading",
        };
      })
      .addCase(fetchTableHiddenFields.fulfilled, (state, { payload }) => {
        return {
          ...state,
          hiddenFields: payload.data,
        };
      })
      .addCase(fetchTableHiddenFields.rejected, (state) => {
        return {
          ...state,
          profileStatus: "error",
        };
      })
      .addCase(fetchProfileCounterparties.pending, (state) => {
        return {
          ...state,
          profileStatus: "loading",
        };
      })
      .addCase(fetchProfileCounterparties.fulfilled, (state, { payload }) => {
        return {
          ...state,
          counterparties: payload.data,
        };
      })
      .addCase(fetchProfileCounterparties.rejected, (state) => {
        return {
          ...state,
          profileStatus: "error",
        };
      })
      // .addCase(fetchProfiledefaultsecurity.pending, (state) => {
      //   return {
      //     ...state,
      //     profileStatus: "loading",
      //   };
      // })
      // .addCase(fetchProfiledefaultsecurity.fulfilled, (state, { payload }) => {
      //   return {
      //     ...state,
      //     default_security_set: {
      //       security_id: payload.data.security_id,
      //       conid: payload.data.conid,
      //       iex_symbol: payload.data.iex_symbol,
      //       market_id: payload.data.market_id,
      //       is_update: false,
      //       tabName: payload.data.tabName,
      //       isin_code: payload.data.isin_code,
      //       ib_exchange: payload.data.ib_exchange,
      //       currency: payload.data.currency,
      //     },
      //   };
      // })
      // .addCase(fetchProfiledefaultsecurity.rejected, (state) => {
      //   return {
      //     ...state,
      //     profileStatus: "error",
      //   };
      // })

      .addCase(fetchProfileCurrency.pending, (state) => {
        return {
          ...state,
          profileCurrencyStatus: "loading",
        };
      })
      .addCase(fetchProfileCurrency.fulfilled, (state, { payload }) => {
        // console.log("fetchProfileCurrency", payload);
        return {
          ...state,
          fx_currency_data: {
            ...state.fx_currency_data,
            selected_currency: payload.data,
          },
        };
      })
      .addCase(fetchProfileCurrency.rejected, (state) => {
        return {
          ...state,
          profileCurrencyStatus: "error",
        };
      })
      .addCase(fetchProfilePortfolio.pending, (state) => {
        return {
          ...state,
          profilePortFolioStatus: "loading",
        };
      })
      .addCase(fetchProfilePortfolio.fulfilled, (state, { payload }) => {
        return {
          ...state,
          profilePortFolioStatus: "success",
          portfoliosIds: payload.data.split(","),
          profile: {
            ...state.profile,
            selected_portfolio: payload.data,
          },
          iscall_portfolio_order: true,
        };
      })

      .addCase(fetchDefaultLoadData.pending, (state) => {
        return {
          ...state,
          is_load_security: false,
          fetch_counter_status: "loading",
        };
      })
      .addCase(fetchDefaultLoadData.fulfilled, (state, { payload }) => {
        return {
          ...state,
          is_load_security: Number(payload.status) === 1 ? true : false,
          is_load_security_message: payload.message,
          fetch_counter_status: "success",
          default_security_set: {
            security_id: payload.data.security_id,
            conid: payload.data.conid,
            iex_symbol: payload.data.iex_symbol,
            market_id: payload.data.market_id,
            is_update: false,
            tabName: payload.data.tabName,
            isin_code: payload.data.isin_code,
            ib_exchange: payload.data.ib_exchange,
            currency: payload.data.currency,
          },
        };
      })
      .addCase(fetchDefaultLoadData.rejected, (state) => {
        return {
          ...state,
          is_load_security: false,
          fetch_counter_status: "error",
        };
      })
      .addCase(fetchkillcount.pending, (state) => {
        return {
          ...state,
          fetch_counter_status: "loading",
        };
      })
      .addCase(fetchkillcount.fulfilled, (state, { payload }) => {
        return {
          ...state,
          kill_counter: payload.data.trades_count,
          fetch_counter_status: "success",
        };
      })
      .addCase(fetchkillcount.rejected, (state) => {
        return {
          ...state,
          fetch_counter_status: "error",
        };
      })
      .addCase(fetchKill.pending, (state) => {
        return {
          ...state,
          profilePortFolioStatus: "loading",
        };
      })
      .addCase(fetchKill.fulfilled, (state, { payload }) => {
        // console.log("killed ", payload);
        toast.success(payload.message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return {
          ...state,
          killed: 14,
        };
      })
      .addCase(fetchKill.rejected, (state) => {
        return {
          ...state,
          profilePortFolioStatus: "error",
        };
      })
      .addCase(postBulkTradeUpload.pending, (state) => {
        return {
          ...state,
          bulkUploadStatus: "loading",
        };
      })
      .addCase(postBulkTradeUpload.fulfilled, (state, { payload }) => {
        // console.log(payload);
        return {
          ...state,
          bulkUploadStatus: "success",
        };
      })
      .addCase(postBulkTradeUpload.rejected, (state) => {
        return {
          ...state,
        };
      })
      .addCase(postUserMenu.pending, (state) => {
        return {
          ...state,
          menuDataStatus: "loading",
        };
      })
      .addCase(postUserMenu.fulfilled, (state, { payload }) => {
        // console.log("api", payload.data);
        return {
          ...state,
          menuData: payload.data,
          menuDataStatus: "success",
        };
      })
      .addCase(postUserMenu.rejected, (state) => {
        return {
          ...state,
          menuDataStatus: "error",
        };
      });
  },
});

export const {
  updateSelectedPortFolio,
  updateStartDate,
  updateEndDate,
  updateAllPortFolio,
  updateDefaultSecuritySet,
  selectAllPortFolio,
  addHiddenFields,
  removeHiddenFields,
  updateDateRange,
  update_is_report_country,
  update_report_send_time,
  update_country_ids,
  update_regions_ids,
  update_current_currency,
  update_iscall_portfolio_order,
  update_kill_counter,
  update_is_socket_connected,
  update_socket_connect_obj,
  update_security_subscription,
  update_security_unsubscription,
  update_emit_lineTimeline,
  update_socket_remove_all_listeners,
  update_IB_price_data,
  update_rfs_stream_channel_data,
  update_rfq_quote_data,
  update_trade_notification_data,
  search_portfolio,
} = DashBoard.actions;
export const profile = (state: RootState) => state.dashBoard.profile;
export const default_security_set = (state: RootState) => state.dashBoard.default_security_set;
export const security_subscription = (state: RootState) => state.dashBoard.security_subscription;
export const full_security_name = (state: RootState) => state.dashBoard.full_security_name;
export const is_rfq_access = (state: RootState) => state.dashBoard.is_rfq_access;

export const is_allow_execution_and_settlement = (state: RootState) => state.dashBoard.is_allow_execution_and_settlement;

export const portfolios = (state: RootState) => state.dashBoard.portfolios;

export const trading_portfolios = (state: RootState) => state.dashBoard.trading_portfolios;

export const fx_currency_data = (state: RootState) => state.dashBoard.fx_currency_data;

export const portfoliosIds = (state: RootState) => state.dashBoard.portfoliosIds;

export const counterparties = (state: RootState) => state.dashBoard.counterparties;

export const selected_portfolio = (state: RootState) => state.dashBoard.selected_portfolio;

export const profile_selected_portfolio = (state: RootState) => state.dashBoard.profile.selected_portfolio;

export const profile_selected_currency = (state: RootState) => state.dashBoard.profile.selected_currency;

export default DashBoard.reducer;
