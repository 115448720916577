import React from "react";

type Props = {};

const DropdownArrow = (props: Props) => {
  return (
    // <svg width="9px" className="min-w-[9px] fill-brandPrimary" height="18px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
    //   <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
    // </svg>

    <svg xmlns="http://www.w3.org/2000/svg" className="min-w-[9px]" viewBox="0 0 320 512">
      <path
        fill="#071932"
        d="M299.3 244.7c6.2 6.2 6.2 16.4 0 22.6l-192 192c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6L265.4 256 84.7 75.3c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0l192 192z"
      />
    </svg>
  );
};

export default DropdownArrow;
