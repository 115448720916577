import React from "react";
import { useAppSelector } from "../../Redux/app/hooks";

type Props = {};

const Breadcrumb = (props: Props) => {
  const menu = useAppSelector((state) => state.dashBoard.profile.department);

  return (
    <ul className="breadcrumb">
      {menu !== 1 ? (
        <>
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/">Clients</a>
          </li>
          <li>
            <a href="/">Bookings</a>
          </li>
          <li className="active">Order Entry</li>
        </>
      ) : (
        <>
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/">Bookings</a>
          </li>
          <li className="active">Order Entry</li>
        </>
      )}
    </ul>
  );
};

export default Breadcrumb;
